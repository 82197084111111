@font-face {
  font-family: 'New Spirit Web';
  src: url(../fonts/NewSpirit-Bold.eot);
    src: url(../fonts/NewSpirit-Bold.otf) format("opentype"),
      url(../fonts/NewSpirit-Bold.ttf) format("truetype");
        font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Web';
  src: url(../fonts/NewSpirit-Medium.eot);
  src: url(../fonts/NewSpirit-Medium.otf) format("opentype"),
       url(../fonts/NewSpirit-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Smiles Web';
  src: url(../fonts/Smiles-PSD-Bold.eot);
  src: url(../fonts/Smiles-PSD-Bold.otf) format("opentype"),
       url(../fonts/Smiles-PSD-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Smiles Web';
  src: url(../fonts/Smiles-PSD-Regular.eot);
  src: url(../fonts/Smiles-PSD-Regular.otf) format("opentype"),
       url(../fonts/Smiles-PSD-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Smiles Web';
  src: url(../fonts/Smiles-PSD-Light.eot);
  src: url(../fonts/Smiles-PSD-Light.otf) format("opentype"),
    url(../fonts/Smiles-PSD-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}